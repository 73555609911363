import 'lib-flexible'
import { y }  from  './public/js/young.js'
import './public/scss/common.scss'
import './public/scss/index.scss'
import './public/scss/case.scss'
import './public/scss/inside.scss'
import './public/scss/inside2.scss'
if(y('.index')[0]){
  let hooboo = new Swiper(".hooboo", {
    autoplay: true,
    speed: 0,
  });
 
}else{
  if(y('body>.website')[0]){
    let flag = 0
    let timer = setInterval(function(){
      flag++
      y('.websiteSloganNumber div .number span')[0].innerText = flag
      if(flag>=900){
        clearInterval(timer)
      }
    },3)
  }
  
  if(y('body>.about')[0]){
    new Swiper('.about .banner .swiper-container',{
      slidesPerView : 'auto',
      autoplay:true,
      spaceBetween:10,
      loop:true,
      speed:1000
    })
  }
}
let ulheight = []
document.addEventListener('click',function(){
  y('.y-select').forEach(function (item, index) {
    item.querySelector('ul').style = `height:0 ;visibility:hidden;top:${-ulheight[index]}px;top:0;`;
  })
})
y('.y-select').forEach(function (item, index) {
  item.addEventListener('click',function(e){
    window.event? window.event.cancelBubble = true : e.stopPropagation();
  },false)
  y('.y-select ul').forEach(function (item, index) {
      ulheight.push(item.offsetHeight);
      item.style = `height:0 ;visibility:hidden;top:${-ulheight[index]}px;top:0;`;
  });
  item.querySelector('span').onclick = function () {
      let that = this;
      if (this.nextElementSibling.style.visibility === 'visible') {
          this.nextElementSibling.style.height = '0';
          this.nextElementSibling.style.visibility = 'hidden';
              that.nextElementSibling.style.top = '0';
              this.classList.remove('icon');
      }
      else {
          this.classList.add('icon');
          this.nextElementSibling.style = `height:0 ;visibility:visible;top:${-ulheight[index]}px;top:0;`;
          setTimeout(function () {
              that.nextElementSibling.style.height = ulheight[index] + 'px';
              that.nextElementSibling.style.top = -ulheight[index]+ 'px';
          }, 0);
      }
  };
  item.querySelectorAll('li').forEach(function (item, index) {
      item.onclick = function () {
          this.parentNode.parentNode.querySelector('span').innerHTML = `<span>${this.innerText}</span>`;
          this.parentNode.parentNode.querySelector('span').classList.remove('icon');
          this.parentNode.parentNode.querySelector('input').value = this.innerText;
          this.parentNode.style.height = '0';
          this.parentNode.style.top = '0';
          this.parentNode.style.visibility = 'hidden';
      };
  });
});
document.body.offsetWidth<1440?window.location.replace('/redirect.html'):''